import * as React from "react";
import Layout from "../../components/layout";

// markup
const ServiceEndPage = () => {
  return (
    <Layout
      title="【iOS/Android版】サービス終了のご案内 | CO Record &amp; journal"
      policyStyle={true}
      showHeader={true}
      isTop={false}
    >
      <main className="pageStyles">
        <div>
          <div className="staff-image-parent">
            <img
              className="staff-image"
              src="../images/logo_red.png"
              alt="co_logo"
            />

            <h2>【iOS/Android版】CO Recourd & Journal サービス終了について</h2>
            <div class="end-contents">
              <h2>【終了するサービス】</h2>
              <p>
                ■ iOS/Android版 CO Recourd &
                Journal（シーオーレコードアンドジャーナル）
              </p>
            </div>

            <div class="schedule">
              <h2>【サービス終了までの日程】</h2>
              <p>
                ■ 2023/12/22(金) 12:00 iOS/Android版 CO Recourd & Journal
                サービス終了
              </p>
              <p>
                ※
                サービス終了後は、ログイン・シーズンやマップの確認など、すべての機能がご利用いただけなくなります。
              </p>
            </div>

            <div class="qa">
              <h2>【Ｑ＆Ａ】</h2>
              <p>
                <strong>
                  ・【CO Recourd & Journal】貯めていたアイテムは無くなりますか？
                </strong>
              </p>
              <p class="pl-2">
                →
                無くなります。サービス終了までにご利用頂きますようお願い申し上げます。
              </p>
              <p>
                <strong>
                  ・【CO Recourd &
                  Journal】歩数記録や位置情報の履歴はどうなりますか？
                </strong>
              </p>
              <p class="pl-2">
                →
                サービス終了すると、サーバーからは削除されユーザーによる確認ができなくなります。
              </p>
              <p class="pl-2">
                サービス終了後は匿名加工情報として保管させていただきます。
              </p>
              <p>
                <strong>
                  ・【CO Recourd &
                  Journal】歩数記録や位置情報を出力してほしいのですが
                </strong>
              </p>
              <p class="pl-2">→ 申し訳ございませんが、対応しておりません。</p>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default ServiceEndPage;
